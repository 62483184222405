<template>
  <div class="rn_my_wrap">
    <GoodsSearch />
    <!---->
    <!-- <div class="rn_my_empty_30"></div> -->
    <!---->
    <MainMenu
      :mainMenuList="mainMenuList"
      @getMenu="getMenu"
      :isGoods="true"
      :allowMenu="allowMenu"
    />
    <!---->
    <div class="rn_ms_section goods_border">
      <!---->
      <div class="cate_wrap">
        <div class="cate_left">
          <router-link to="/market_category_list"
            ><img src="@/assets/images/sub/cate_menu_ico.png" alt=""
          /></router-link>
        </div>
        <div class="cate_right">
          <!-- -->
          <GoodsCategory
            :category="categories"
            :id="id.toString()"
            ref="goodsCategory"
            @getGoodsCategory="getGoodsCategory"
          />
          <!-- -->
        </div>
      </div>
      <!---->
      <div class="rn_ms_box_4">
        <div class="sel_sort">
          <div>
            <router-link
              to=""
              class="list_sort on"
              @click.native="listSort('newly', $event)"
              >최신순</router-link
            >
            <router-link
              to=""
              class="list_sort"
              @click.native="listSort('popular_sales', $event)"
              >판매순</router-link
            >
            <router-link
              to=""
              class="list_sort"
              @click.native="listSort('reviewer', $event)"
              >리뷰수순</router-link
            >
          </div>
          <div>
            <router-link
              to=""
              class="list_sort"
              @click.native="listSort('low_price', $event)"
              >낮은 가격순</router-link
            >
            <router-link
              to=""
              class="list_sort"
              @click.native="listSort('high_price', $event)"
              >높은 가격순</router-link
            >
          </div>
        </div>
        <div class="sect_location">
          <span @click="goMainList()">상품 ></span>
          <template v-for="(name, index) in categoryName">
            <span
              to=""
              :key="index"
              :id="`${categoryName.length}_${index}`"
              :data-level="name.level"
              :data-id="name.id"
              :class="{ shape_yellow: parseInt(name.level) === 4 }"
              v-if="categoryName.length - 1 > index"
              @click="goCategory($event)"
            >
              {{ name.title }} >
            </span>
            <b
              class="shape_red"
              v-if="categoryName.length - 1 <= index"
              :key="index"
              :id="`${categoryName.length}_${index}`"
              :data-id="name.id"
              :data-level="name.level"
              @click="goCategory($event)"
              >{{ name.title }}
            </b>
          </template>
          <ReviewGoodsEndCategoryVue
            v-if="categoryName.length > 0"
            :nowId="categoryName[categoryName.length - 1].id"
            @moveCategory="moveCategory"
            ref="end_category"
          />
        </div>
        <div class="goods_list" v-if="listArray.length > 0">
          <div
            class="box"
            v-for="(list, index) in listArray"
            :key="`${list.goods_seq}_${index}`"
          >
            <div class="top">
              <router-link :to="`/market_goods_detail/${id}/${list.goods_seq}`">
                <div class="img">
                  <img
                    :src="list.image"
                    @error="replaceImg"
                    :alt="list.goods_name"
                  />
                </div>
                <div class="txt">
                  <h3>{{ list.goods_name }}</h3>
                  <p v-if="parseInt(list.provider_seq) == 1">
                    램 가게 &gt; 본사
                  </p>
                  <p v-else>램 가게 &gt; {{ list.provider_name }}</p>
                </div>
              </router-link>
            </div>
            <ul class="bottom">
              <li>
                가격 : <b>{{ won(list.sale_price) }}원</b>
              </li>
              <li class="sale_per" v-if="parseInt(list.sale_per) > 0">
                {{ list.sale_per }}%
              </li>
              <li :style="parseInt(list.sale_per) <= 0 ? 'width:180px;' : ''">
                <router-link to="" @click.native="movePage(index)"
                  >리뷰보기</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="goods_list no_data" v-else>
          <p>카테고리에 상품 존재하지 않습니다.</p>
        </div>
        <div class="rn_more" v-if="parseInt(totalPage) > parseInt(page)">
          <router-link to="" @click.native="getGoodsList()">
            {{ limit }}개 더보기<img
              src="@/assets/images/sub/my_down_ico.png"
              alt=""
          /></router-link>
        </div>
      </div>
      <!---->
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainMenu from "@/components/shop/menu/MainMenu";
import GoodsCategory from "@/components/shop/menu/GoodsCategory";
import ReviewGoodsEndCategoryVue from "@/components/review/state/ReviewGoodsEndCategory.vue";
import GoodsSearch from "@/components/shop/menu/GoodsSearch.vue";
export default {
  data() {
    return {
      first: true,
      id: this.$route.params.id || null,
      level: this.$route.params.level || null,
      lowId: 0,
      page: 0,
      sort: "newly",
      keyword: "",
      ctegoryCode: null,
      allowMenu: [1], //현재 램 장터메 활성화된 메뉴 번호
      searchText: "",
    };
  },
  components: {
    MainMenu,
    GoodsCategory,
    ReviewGoodsEndCategoryVue,
    GoodsSearch,
  },
  created() {
    //초기화
    this.$store.dispatch("shop/dataClear");
    this.getMainMenu();
  },
  computed: {
    ...mapState("review", ["mainMenuList"]),
    ...mapState("shop", [
      "result",
      "msg",
      "categories",
      "listArray",
      "totalPage",
      "categoryName",
      "limit",
    ]),
  },
  methods: {
    moveCategory(payload) {
      this.id = parseInt(payload.id);
      this.$router.push(`/market_goods_list/5/${payload.id}`);
      [...document.querySelectorAll(".list_sort")].map((sort) =>
        sort.classList.remove("on")
      );
      [...document.querySelectorAll(".list_sort")][0].classList.add("on");
      this.sort = "newly";
      this.getGoodsList(true, false);
    },
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    movePage(index) {
      const data = this.listArray[index];
      const url = `/review/goods_main/${
        data.r_category[data.r_category.length - 1]
      }?goods_seq=${data.goods_seq}&option1=1&option2=0&option3=0&seq=0`;
      this.$router.push(url);
    },
    goMainList() {
      this.$router.push("/market_main_list").catch(() => {});
    },
    listSort(sort, event) {
      [...document.querySelectorAll(".list_sort")].map((sort) =>
        sort.classList.remove("on")
      );
      event.target.classList.add("on");
      this.sort = sort;
      this.getGoodsList(true);
    },
    won(str) {
      return this.$won(str);
    },
    getDataSearch() {
      //전혀 다른 검색화면
    },
    getGoodsCategory(id) {
      if (!this.first) {
        this.$router.push(`/market_main_list/${id}`);
      }
      this.first = !this.first;
    },
    goCategory(event) {
      console.log(event);
      if (event.target.classList.contains("shape_red")) {
        this.$refs["end_category"].showSelectList();
        return true;
      }
      const id = event.target.dataset.id;
      const level = event.target.dataset.level;
      if (parseInt(level) <= 1) {
        this.$router.push(`/market_main_list/${id}`);
      } else if (parseInt(level) >= 2 && parseInt(level) < 5) {
        this.$router.push(`/market_sub_list/${level}/${id}`);
      } else {
        this.goLowCategory(id, level);
      }
    },
    goLowCategory(id, level) {
      this.lowId = id;
      this.level = level;
      this.getGoodsList(true, false);
    },
    async getMainMenu() {
      await this.$store.dispatch("review/getMainMenu");
      this.getGoodsList(true, true);
    },
    async getGoodsList(reset = false, category = false) {
      if (reset) {
        this.page = 0;
      }
      await this.$store.dispatch("shop/getGoodsList", {
        id: this.id,
        level: this.level,
        sort: this.sort,
        page: this.page,
        reset,
      });

      // if (this.listArray.lenght <= 0) {
      //   this.$toast.default("존재하는 상품이 없습니다.");
      //   this.$router.go(-1);
      //   return false;
      // }
      if (!this.result) {
        this.$toast.default(this.msg);
        // this.$router.go(-1);
        // return false;
      }
      if (category) {
        // this.$refs.goodsCategory.reflash();
        let index = this.categories.findIndex(
          (x) => parseInt(x.id) === parseInt(this.categoryName[0].id)
        );
        this.$refs.goodsCategory.reflash(index);
      }
      this.page++;
    },
    getMenu(num) {
      if (parseInt(num) === 1) {
        this.goMainList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.goods_list {
  .box {
    .bottom {
      .sale_per {
        display: inline-block;
        width: 40px !important;
        border-radius: 3px;
        text-align: center !important;
        font-size: 12px;
        font-weight: normal;
        background: #ffc71a;
        color: white;
      }
    }
  }
  &.no_data {
    text-align: center;
  }
}
.sub_list {
  .box {
    .img {
      box-sizing: border-box;
    }
  }
}
.shape_red::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 2px;
  vertical-align: middle;
  border-radius: 50%;
}
.shape_red::before {
  background: red;
}
</style>
